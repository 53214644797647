<template>
	<div class="store__container">
		<div class="white_page_wrapper">
			<StanLogoSvg />

			<div class="content-container">
				<StanleyErrorSvg :width="300" :height="300"/>
				<div class="content-message">There was an issue. Checkout our <a :href="homePageUrl">home page</a>.</div>
			</div>
			<div class="login-container">
			</div>
		</div>
	</div>
</template>
  
<script>
    import StanLogoSvg from '~/assets/images/stan-logo.svg'
    import StanleyErrorSvg from '~/assets/images/stanely_error.svg'

    export default {
		components: {
			StanLogoSvg,
			StanleyErrorSvg,
		},
        props: {
            error: {
            type: Object,
            default: null
            }
        },
        layout: 'error',	
        computed: {
            homePageUrl() {
                return window.location.origin
            }
        },
        mounted() {
            this.$sentry.captureException(new Error(this.error?.message || "Something went wrong"), {
                    extra: {
                        ...this.error, src: "error layout nuxt"
                    },
                })
        }
    }
  </script>
  <style lang="scss" scoped>
  @import '~/stan-vue-shared/assets/styles/themes/index.scss';

  .store__container {
      background: #ebebff;
      .white_page_wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          padding-top: 40px;
          font-family: 'Plus Jakarta Sans', sans-serif;
          color: #131f60;
          font-weight: 400;

          .content-container {
              display: flex;
              flex-direction: column;
              gap: 38px;
              justify-content: center;
              align-items: center;
              width: 100%;

              .content-message {
                  text-align: center;
                  max-width: 80%;
                  font-size: 20px;
                  font-weight: 600;
              }
          }

          .login-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              width: 100%;

              .login-message {
                  margin-bottom: 28px;
              }

              .button {
                  display: flex;
                  border: 2px solid #6355ff;
                  border-radius: 8px;
                  width: 100%;
                  height: 48px;
                  justify-content: center;
                  align-items: center;
                  font-weight: 700;
                  cursor: pointer;

                  &.button-primary {
                      color: #ffffff;
                      background: #6355ff;
                      margin-bottom: 16px;
                      max-width: 720px;
                      transition: 0.2s ease;
                  }
                  &.button-secondary {
                      color: #6355ff;
                      max-width: 720px;
                      transition: 0.2s ease;
                  }
              }
          }
      }
  }
</style>
